<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">总体规划所</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">总体规划所主要职责：</span></b> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">主要承担历版大连总体规划制定和修编工作，为政府、部门提供智库服务。重点制定城市中长期发展规划，各级国土空间总体规划，绿地和生态环境类专项规划，新型城镇化和乡村振兴类规划等，开展重大建设项目选址论证工作。</span>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>所长：高程</b></span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>副所长：柳广杰、韩然屹</b></span> 
        </p>
        <img width="80%" style="margin: 10px 10% 0" src="../../assets/htmlimg/“总体规划所—旧 ”大.jpeg" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>